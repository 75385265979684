<template>
  <div class="container">
    <pcPublicHeader :pageKey="'home'" />
    <div class="public-content">
      <div class="page-header">
        <pcHomeBanner :list="bannerList" @bannerJump="bannerJump" />
        <div class="user-info-item">
          <router-link :to="{ path: '/login' }" v-if="!userInfo || !userInfo.Id" class="login-pop"></router-link>
          <div v-if="userInfo && userInfo.FzLevel >= 50" class="website">
            <div class="bg img-cover"><img src="../../assets/pc-images/user-website-bq.png" /></div>
            <div class="name">{{ userInfo.FName }}</div>
          </div>
          <div class="user-info">
            <div class="header img-cover">
              <img v-if="userInfo && userInfo.Header" :src="userInfo.Header" />
              <img v-else src="../../assets/images/default-header.png" />
            </div>
            <div class="nickname">
              <span v-if="userInfo.Nickname">{{ userInfo.Nickname }}</span>
              <span v-else-if="userInfo.Phone">{{ userInfo.Phone | phoneFormat }}</span>
              <span v-else>用户</span>
            </div>
            <div v-if="userInfo.Icon" class="icon img-contain"><img :src="userInfo.Icon" /></div>
          </div>
          <div class="balance-item">
            <div class="title">账户余额</div>
            <div class="detail">
              <div class="money">{{ userInfo && userInfo.Balance ? Math.round(userInfo.Balance * 10000) / 10000 : 0 }}</div>
              <router-link v-if="userInfo && userInfo.UserIsCz == 1" class="btn" :to="{path: '/balance'}">充值</router-link>
            </div>
          </div>
        </div>
      </div>
      <div class="message-item">
        <div class="icon img-cover"><img src="../../assets/pc-images/msg-icon.png" /></div>
        <van-swipe class="detail-item" vertical :autoplay="4000" :show-indicators="false">
          <van-swipe-item v-for="(item, index) in vipTipsList" :key="index">
            <div class="item">恭喜尾号{{ item.phone }}用户，成功晋升为{{ item.vip }}</div>
          </van-swipe-item>
        </van-swipe>
        <div class="right img-cover"><img src="../../assets/pc-images/msg-right-icon.png" /></div>
      </div>
      <div class="nav-container">
        <div class="left-item">
          <router-link :to="{ path: '/collection' }" class="item sc-item">
            <div class="icon img-cover"><img src="../../assets/pc-images/home-nav-sc-icon.png" /></div>
            <div class="name">我的收藏</div>
          </router-link>
          <router-link v-if="userInfo && userInfo.UserIsCz == 1" :to="{ path: '/balance' }" class="item ye-item">
            <div class="icon img-cover"><img src="../../assets/pc-images/home-nav-ye-icon.png" /></div>
            <div class="name">账户余额</div>
          </router-link>
          <router-link :to="{ path: '/people' }" class="item tg-item">
            <div class="icon img-cover"><img src="../../assets/pc-images/home-nav-tg-icon.png" /></div>
            <div class="name">我的推广</div>
          </router-link>
          <router-link :to="{ path: '/balanceRecord' }" class="item sz-item">
            <div class="icon img-cover"><img src="../../assets/pc-images/home-nav-sz-icon.png" /></div>
            <div class="name">收支明细</div>
          </router-link>
          <router-link :to="{ path: '/order' }" class="item dd-item">
            <div class="icon img-cover"><img src="../../assets/pc-images/home-nav-dd-icon.png" /></div>
            <div class="name">我的订单</div>
          </router-link>
          <router-link v-if="showSstz" :to="{ path: '/notice' }" class="item ss-item">
            <div class="icon img-cover"><img src="../../assets/pc-images/home-nav-ss-icon.png" /></div>
            <div class="name">消息通知</div>
          </router-link>
        </div>
        <div class="right-item">
          <!-- <router-link :to="{ path: '/watermark' }" class="item img-cover">
            <img src="../../assets/pc-images/home-nav-qsy-icon.png" />
          </router-link> -->
          <div class="item img-cover" @click="handleWait">
            <img src="../../assets/pc-images/home-nav-qsy-icon.png" />
          </div>
          <router-link :to="{path: '/website'}" class="item img-cover">
            <img src="../../assets/pc-images/home-nav-fz-icon.png" />
          </router-link>
          <router-link :to="{ path: '/vip' }" class="item img-cover">
            <img src="../../assets/pc-images/home-nav-hy-icon.png" />
          </router-link>
        </div>
      </div>
      <div class="page-title-item">
        <div class="icon img-cover"><img src="../../assets/pc-images/home-product-icon.png" /></div>
        产品列表
      </div>
      <div class="class-container">
        <div class="class-list">
          <van-tabs type="card" v-model="classId" :line-width="0" :line-height="0" :swipe-threshold="1">
            <van-tab :name="0">
              <div slot="title" class="class-item">全部</div>
            </van-tab>
            <van-tab v-for="(item, index) in classList" :key="index" :name="item.Id">
              <div slot="title" class="class-item">
                <div class="icon img-cover"><img :src="item.CategoryThumb" /></div>
                {{ item.Name }}
              </div>
            </van-tab>
          </van-tabs>
        </div>
        <router-link :to="{ path: '/class' }" class="more-item">
          查看更多
          <div class="icon img-cover"><img src="../../assets/pc-images/right-2-icon.png" /></div>
        </router-link>
      </div>
      <div class="goods-container">
        <div v-if="goodsList.length == 0 && finish && !loading" class="empty-item">
          <div class="img img-cover">
            <img src="../../assets/pc-images/empty-img.png" />
          </div>
        </div>
        <div v-else class="goods-list">
          <router-link  :to="{path: '/goodsDetail', query: {id: item.Id}}" class="goods-item" v-for="(item, index) in goodsList" :key="index">
            <div class="img-item img-cover">
              <img v-if="item.GoodsThumb" :src="item.GoodsThumb" />
              <img v-else src="../../assets/images/no-image-icon.png" />
            </div>
            <div class="detail-item">
              <div class="name">
                <span class="tag" v-if="item.Lable">{{ item.Lable }}</span>
                <span class="word">{{ item.GoodsName }}</span>
              </div>
              <div class="money">{{ parseFloat(item.GoodsPrice).toFixed(4) }}</div>
            </div>
          </router-link>
        </div>
        <div v-if="total > pageCount" class="pagination-item">
          <el-pagination background layout="prev, pager, next" :page-size="pageCount" :total="total" @current-change="handleCurrentPageChange"></el-pagination>
        </div>
      </div>
    </div>
    <template v-for="(item, index) in ggList">
      <el-dialog v-if="item.IsTan == 1" :key="index" class="pc-dialog" :title="item.Title" width="50%" :visible.sync="item.show">
        <div class="gg-popup-container">
          <div class="content-item">
            <div v-html="item.Content"></div>
          </div>
          <div class="btn-item">
            <div class="btn sure" @click="item.show = false">我知道了</div>
          </div>
        </div>
      </el-dialog>
    </template>
  </div>
</template>

<script>
import pcPublicHeader from '../../components/pcPublicHeader.vue'
import pcHomeBanner from '../../components/pcHomeBanner.vue'
import { mapState, mapActions } from 'vuex'

export default {
  name: 'Home',
  components: {
    pcPublicHeader,
    pcHomeBanner
  },
  data() {
    return {
      vipTipsList: [],
      bannerList: [],
      ggList: [],
      classList: [],
      classId: 0,
      goodsList: [],
      pageCount: 21,
      page: 1,
      total: 0,
      finish: false,
      loading: false,
      showSstz: false
    }
  },
  computed: {
    ...mapState(['userInfo', 'websiteInfo'])
  },
  filters: {
    phoneFormat(text) {
      if (text) {
        return text.substr(0, 3) + '****' + text.substr(-4)
      }
      return ''
    },
  },
  watch: {
    classId (val) {
      this.handleClassChange(val)
    }
  },
  mounted(){
    this.GetWebsiteInfo()
    this.getBannerData()
    this.getClassList()
    this.handleSetVipTipsData()
    this.handleGetSetting()
    this.getGgList()
  },
  methods: {
    ...mapActions(['GetWebsiteInfo']),
    handleWait() {
      this.$dialog.alert({
        type: 'danger',
        className: 'pc-dialog',
        title: '提示',
        message: '正在开发中，敬请期待',
        confirmButtonText: '确定'
      })
    },
    async getGgList() {
      const { websiteInfo } = this
      var res = await this.$API.announcedList.post({
        AdminId: websiteInfo.Id
      });
      if (res && res.info && res.info.length > 0) {
        const data = []
        for (let index = 0; index < res.info.length; index++) {
          const item = res.info[index]
          data.push({
            ...item,
            show: true
          })
        }
        this.ggList = data
      }
    },
    async handleGetSetting() {
      var res = await this.$API.getSet.post({
        'Key': 'cz_show'
      })
      const { userInfo } = this
      if (userInfo && userInfo.Id) {
        if (userInfo.UserIsSs == 1 && parseFloat(userInfo.AllMoney) >= parseFloat(res.info)) {
          this.showSstz = true
        } else {
          this.showSstz = false
        }
      } else {
        if (res.info == -1) {
          this.showSstz = true
        } else {
          this.showSstz = false
        }
      }
    },
    async handleSetVipTipsData() {
      var vips = await this.$API.vipMoney.post({
        Type: 1
      })
      const nums = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9]
      const list = []
      for (let i = 0; i < 50; i++) {
        list.push({
          phone: '' + this.getValue(nums) + this.getValue(nums) + this.getValue(nums) + this.getValue(nums),
          vip: this.getValue(vips)['Name']
        })
      }
      this.vipTipsList = list
    },
    getValue(data) {
      let random = Math.random()
      let num = Math.round(random * (data.length - 1))
      return data[num]
    },
    async getBannerData() {
      const { websiteInfo } = this
      var res = await this.$API.bannerList.post({
        AdminId: websiteInfo.Id
      });
      this.bannerList = res.info || []
    },
    async getClassList() {
      var res = await this.$API.goodsCategoryList.post();
      if (res.info && res.info.length > 0) {
        this.classList = [
          ...res.info
        ]
      }
      this.getGoodsList()
    },
    handleClassChange(id) {
      this.classId = id
      this.page = 1
      this.goodsList = []
      this.loading = false
      this.finish = false
      this.getGoodsList()
    },
    handleCurrentPageChange(val) {
      this.page = val
      this.getGoodsList()
    },
    async getGoodsList() {
      const { userInfo } = this
      if (userInfo && userInfo.Id && userInfo.UserIsGoods != 1) {
        this.finish = true
        this.loading = false
        return
      }
      const { loading, pageCount, page, classId, websiteInfo } = this
      if (loading) {
        return
      }
      const params = {
        PageCount: pageCount, 
        Page: page,
        AdminId: websiteInfo.Id,
        IsIndex: 1
      }
      if (classId) {
        params.CategoryId = classId
      }
      this.loading = true
      var res = await this.$API.goodsList.post(params)
      this.goodsList = [
        ...res.info
      ]
      if (page == 1) {
        this.total = res.count
      }
      this.finish = res.info.length < pageCount
      this.loading = false
    },
    bannerJump(item) {
      if (item.ParentId && item.Type == 1) {
        this.$router.push({
          path: '/goodsDetail',
          query: {
            id: item.ParentId
          }
        })
      }
      if (item.ParentId && item.Type == 2) {
        window.open(item.ParentId)
      }
    }
  }
}
</script>

<style lang="less" scoped>
.page-header {
  padding-top: 30px;
  display: flex;
  justify-content: space-between;
  .user-info-item {
    position: relative;
    width: 500px;
    height: 200px;
    background-color: #fff;
    border-radius: 12px;
    position: relative;
    .login-pop {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 10;
    }
    .website {
      width: 124px;
      height: 56px;
      position: absolute;
      right: 2px;
      top: 2px;
      .bg {
        width: 100%;
        height: 100%;
      }
      .name {
        position: absolute;
        right: 0;
        top: 0;
        height: 34px;
        width: 92px;
        text-align: center;
        line-height: 34px;
        color: #333;
        font-size: 13px;
        font-weight: bold;
      }
    }
    .user-info {
      padding-top: 20px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      .header {
        margin-left: 22px;
        width: 60px;
        height: 60px;
        border-radius: 50%;
        overflow: hidden;
      }
      .nickname {
        margin-left: 12px;
        color: #333;
        font-weight: bold;
      }
      .icon {
        height: 20px;
        margin-left: 6px;
      }
    }
    .balance-item {
      margin: 22px 22px 0;
      height: 72px;
      border-radius: 12px;
      background: linear-gradient( 90deg, #7683ff 0%, #6a5ffe 100%);
      .title {
        padding: 10px 0 0 15px;
        font-size: 12px;
        color: rgba(255, 255, 255, .6);
      }
      .detail {
        padding: 4px 12px 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .money {
          font-size: 28px;
          line-height: 30px;
          font-weight: bold;
          color: #fff;
        }
        .btn {
          width: 85px;
          height: 30px;
          border-radius: 15px;
          background-color: #fff;
          text-align: center;
          line-height: 30px;
          font-size: 12px;
          color: #6a5ffe;
          font-weight: 500;
        }
      }
    }
  }
}
.message-item {
  margin-top: 44px;
  width: 100%;
  height: 42px;
  border-radius: 6px;
  background-color: rgba(106, 95, 254, .12);
  display: flex;
  justify-content: space-between;
  align-items: center;
  .icon {
    width: 30px;
    height: 30px;
    margin-left: 12px;
  }
  .detail-item {
    flex: 1;
    height: 30px;
    padding: 0 8px;
    .item {
      font-size: 14px;
      color: #000;
      line-height: 30px;
    }
  }
  .right {
    width: 20px;
    height: 20px;
    margin-right: 12px;
  }
}
.nav-container {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .left-item {
    width: 700px;
    padding-top: 8px;
    box-sizing: border-box;
    border-radius: 12px;
    background: linear-gradient( 90deg, #e5f5fd 0%, #dedff6 100%);
    .item {
      cursor: pointer;
      width: 214px;
      height: 52px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      border: 1px solid #fff;
      border-radius: 10px;
      float: left;
      margin: 0 0 8px 14px;
      &:nth-child(1),
      &:nth-child(4n) {
        margin-left: 12px;
      }
      &.sc-item {
        background: linear-gradient( 90deg, #fff 0%, #ffe2c6 100%);
      }
      &.ye-item {
        background: linear-gradient( 90deg, #fff 0%, #fad3d3 100%);
      }
      &.tg-item {
        background: linear-gradient( 90deg, #fff 0%, #d3fae3 100%);
      }
      &.sz-item {
        background: linear-gradient( 90deg, #fff 0%, #d3e1fa 100%);
      }
      &.dd-item {
        background: linear-gradient( 90deg, #fff 0%, #d3e1fa 100%);
      }
      &.ss-item {
        background: linear-gradient( 90deg, #fff 0%, #d4d1ff 100%);
      }
      .icon {
        margin-left: 16px;
        margin-right: 10px;
        width: 32px;
        height: 32px;
      }
      .name {
        font-size: 16px;
        font-weight: bold;
      }
    }
  }
  .right-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .item {
      cursor: pointer;
      width: 240px;
      height: 136px;
      margin-left: 20px;
    }
  }
}
.page-title-item {
  margin-top: 20px;
  display: flex;
  align-items: center;
    color: #3d3d42;
    font-size: 20px;
    font-weight: bold;
  .icon {
    width: 42px;
    height: 42px;
    margin-right: 16px;
  }
}
.class-container {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .class-list {
    width: 1350px;
    /deep/ .van-tabs {
      background-color: transparent;
      &>.van-tabs__wrap {
        height: 42px;
      }
      .van-tabs__wrap--scrollable {
        .cover {
          display: none;
        }
        .van-tabs__nav--card {
          padding: 0;
          height: 42px;
          margin: 0;
          border: 0;
          background-color: transparent;
        }
        .van-tab {
          flex: none !important;
          background-color: transparent;
          border: 0;
          margin-right: 14px;
          padding: 0 12px;
          background-color: #fff;
          border: 1px solid #fff;
          border-radius: 6px;
          height: 42px;
          color: #000;
          &.van-tab--active {
            border-color: #6a5ffe;
            background-color: rgba(118,131,255,0.16);
            color: #6a5ffe;
          }
        }
      }
    }
    .class-item {
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      .icon {
        width: 22px;
        height: 22px;
        margin-right: 8px;
      }
    }
  }
  .more-item {
    width: 110px;
    height: 42px;
    border-radius: 6px;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #000;
    font-size: 14px;
    .icon {
      width: 22px;
      height: 22px;
      margin-left: 8px;
    }
  }
}
.goods-container {
  margin-top: 20px;
  .goods-list {
    overflow: hidden;
  }
  .goods-item {
    width: 194px;
    margin: 0 20px 20px 0;
    float: left;
    &:nth-child(7n) {
      margin-right: 0;
    }
    .img-item {
      width: 194px;
      height: 194px;
      border-radius: 12px 12px 0 0;
      overflow: hidden;
    }
    .detail-item {
      background-color: #fff;
      border-radius: 0 0 12px 12px;
      padding: 10px 12px 14px 12px;
      .name {
        height: 36px;
        line-height: 18px;
        font-size: 14px;
        color: #000;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        text-overflow: ellipsis;
        .tag {
          margin-right: 6px;
          display: block;
          float: left;
          padding: 0 8px;
          background: linear-gradient( 90deg, #6a5ffe 0%, #21e8fe 100%);
          height: 18px;
          line-height: 18px;
          font-size: 10px;
          color: #fff;
        }
      }
      .money {
        margin-top: 10px;
        line-height: 24px;
        font-size: 20px;
        color: #f00;
        font-weight: 400;
      }
    }
  }
}
.gg-popup-container {
  .content-item {
    margin: 12px 16px;
    min-height: 92px;
    max-height: 60vh;
    overflow-y: auto;
    line-height: 24px;
    color: #333;
    font-size: 14px;
  }
  .btn-item {
    padding: 0 16px 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    .btn {
      cursor: pointer;
      width: 200px;
      height: 40px;
      color: #fff;
      text-align: center;
      line-height: 40px;
      font-size: 14px;
      font-weight: bold;
      border-radius: 6px;
      background: linear-gradient( 135deg, #58b7ff 0%, #1399ff 100%);
    }
  }
}
</style>
